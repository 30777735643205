.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 30rem;
  /* padding: 1.6rem; */
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  align-items: flex-start; 
  touch-action: pan-y;
  flex-direction: row;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
  transition: height 0.2s;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.embla__slide__img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.embla__dot,
.embla__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.embla__dots {
  @apply flex justify-center items-center my-4;
  z-index: 1;
}

.embla__dot {
  @apply flex items-center m-3;
  width: 0.5rem;
  height: 0.5rem;
}

.embla__dot:after {
  @apply bg-accent;
  border-radius: 0.5rem;
  width: 100%;
  height: 0.5rem;
  content: "";
}

.embla__dot--selected:after {
  @apply bg-secondary bg-opacity-50;
}

.embla__button {
  @apply text-primary;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 4rem;
  height: 4rem;
}

.embla__button--prev {
  left: 0.125rem;
}

.embla__button--next {
  right: 0.125rem;
}

.embla__button:disabled {
  opacity: 0.3;
}

.embla__button__svg {
  width: 65%;
  height: 65%;
}
